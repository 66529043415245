<template>
  <div class="container">
    <div class="title">
      <img :src="basicConfig.Logo" alt />
    </div>
    <div class="swipe-box">
      <van-swipe class="swipe-container" ref="swiper" @change="percentage = 0">
        <van-swipe-item v-for="(item, index) in rotationPics" :key="index" @click="JumpWeb(item.JumpUrl)">
          <img class="bg" :src="item.ImgUrl" alt />
        </van-swipe-item>
      </van-swipe>
      <router-link tag="div" class="btn" v-if="!logined && basicConfig.IsShowBtn" to="Register">
        开设真实账户
      </router-link>
    </div>
    <van-progress :percentage="percentage" stroke-width="2" :color="color" track-color="transparent"
      :show-pivot="false" />

    <Notice />
    <ProductList />
    <div class="block-1">
      <h2 class="title-1">旨在为世界带来优质金融服务</h2>
      <h2 class="title-2">{{ basicConfig.CompanyName }} 交易连接世界</h2>
      <p class="content">
        {{
          basicConfig.CompanyName
        }}总部位于国际金融中心香港，目前已发展成为香港知名的综合性金融服务集团公司。
      </p>
      <p class="content">
        集团业务覆盖东南亚、欧洲、北美等全球金融交易活跃地区，而旗下成员公司持有中国香港、美国等主要金融市场的多类牌照资质,包括香港证监会第1、2、 3、4、5、
        6、9类牌照、美国证券交易委员会(SEC)及美国全国期货协会(NFA)核准会员资质、香港放债人牌照、香港保险经纪业务牌照香港金银业贸易场行员、香港信托或公司服务提供者牌照等。
        <!-- {{
          basicConfig.CompanyName
        }}以“交易连接世界”为使命，致力于通过科技创新，搭建一个开放、透明的“新一代”金融生态系统，让客户仅用一个账户即可自由交易全球一万五千余种金融产品。目前{{
          basicConfig.CompanyName
        }}分公司、经纪人和客户分布于全球200多个国家和地区，月交易量超过一千亿美金。 -->
      </p>
      <div class="inabout-list">
        <div class="item" v-for="item in inaboutList" :key="item.text1">
          <div>
            <strong rel="1.5" cbolexec="true" bolexec="true">{{
              item.text1
            }}</strong>
            <i>+</i>
            <span>{{ item.text2 }}</span>
          </div>
          <p>{{ item.text3 }}</p>
        </div>
      </div>
    </div>
    <router-link tag="div" to="Product" class="more">了解更多<van-icon name="down" /></router-link>
    <video controls="controls" v-if="!basicConfig.IsHideVideo" :src="basicConfig.Video" :poster="basicConfig.VideoCover"
      class="videos"></video>
    <img class="iamge01" src="@/assets/newHome/image01.jpg" alt="" />
    <div class="block-2">
      <div class="logo-text">
        <!-- <img src="@/assets/newHome/logo.png" alt="" /> -->
        <span> {{ basicConfig.CompanyName }}</span>
      </div>
      <div class="box">
        <div class="item" v-for="bItem in boxList" :key="bItem.text1">
          <div class="top">
            <img :src="bItem.imgUrl" alt="" />
          </div>
          <div class="middle">{{ bItem.text1 }}</div>
          <p>{{ bItem.text2 }}</p>
        </div>
      </div>
    </div>
    <div class="block-3">
      <div class="content">
        <h2>强大的交易平台</h2>
        <van-swipe ref="b3Swiper" class="block3-swipe-container" :show-indicators="false" @change="handleChange">
          <van-swipe-item v-for="sItem in block3List" :key="sItem.text1">
            <img :src="sItem.imgUrl" alt />
            <h3>{{ sItem.text1 }}</h3>
            <p>
              {{ sItem.text2 }}
            </p>
            <router-link tag="div" to="Product" class="more">了解更多<van-icon name="down" /></router-link>
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="indicator">
        <span v-for="(iItem, iIndex) in block3List.length" :key="iIndex" @click="handleClick(iIndex)" :class="{
          'b3-active': iIndex === b3Active,
        }"></span>
      </div>

      <div class="content">
        <h2>丰富的交易产品</h2>
        <van-swipe class="block3-swipe-container" :show-indicators="false">
          <van-swipe-item>
            <img src="@/assets/newHome/icon16.png" alt />
            <h3>外汇</h3>
            <p>
              外汇市场每周7天/每天24小时开放，是全球规模最大、流动性最强的金融市场，日交易量超过6万亿美元
            </p>
            <router-link tag="div" to="Product" class="more">了解更多<van-icon name="down" /></router-link>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="footer">
      <div class="block-4">
        <div class="footer-logo">
          <!-- <img src="@/assets/newHome/publicFooter-logo.png" alt /> -->
          <div style="font-size: 24px;margin-bottom: 20px;">{{ basicConfig.CompanyName }}</div>
        </div>
        <h4>免责声明：</h4>
        <p>{{ basicConfig.CompanyName }}{{ basicConfig.CompanyName }}官方网站及或本公司应用程式中的资料仅为阁下提供一般性的普通资料。{{ basicConfig.CompanyName }}有限公司、其附属公司及联系公司(统称「{{ basicConfig.CompanyName }}」)相信其来源可靠。{{ basicConfig.CompanyName }}金融明确地表示不会就任何关于该等资料之(包括但不限于)准确、适当、及时或完整性负上任何责任。所有本网站之资料是根据当时情况而提供，更改亦毋须事先通知阁下。{{ basicConfig.CompanyName }}又明确地表示不会就本网站提供之资料所产生或因依赖该等资料而引致之任何损失承担任何责任。基于互联网的性质，经互联网传送信息可能受干扰、中断、延迟传送及数据被错误传送。如不在{{ basicConfig.CompanyName }}控制范围内的通讯设施发生任何故障，影响阁下传送信息的准确性或时效，{{ basicConfig.CompanyName }}概不负责。凡使用本网站及其内容之使用者，必须自行承担有关风险
        </p>
      </div>
      <div class="footer-info">
        <div class="top">
          <span v-for="fItem in footerList" :key="fItem">{{ fItem }}</span>
        </div>
        <div class="bottom">
          © Copyright 2020 - 版权所有 {{ basicConfig.CompanyName }}集团
        </div>
      </div>
    </div>
    <TabBar bg="#f1f1f1" active="" />
  </div>
</template>

<script>
import TabBar from '@/components/tabBar'
import ProductList from './productList'
import Notice from './notice'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import { Token } from '@/utils/mm'
export default {
  components: {
    TabBar,
    ProductList,
    Notice,
  },
  data() {
    return {
      logined: Token.get() ? true : false,
      percentage: 0,
      rotationPics: [],
      color:
        'linear-gradient(90deg, #06c878 29%, #0093df 100%), linear-gradient(#1f684a, #1f684a)',
      inaboutList: [
        {
          text1: '1.5',
          text2: '万',
          text3: '自由交易全球金融产品（种）',
        },
        {
          text1: '200',
          text2: '国',
          text3: '分公司、经纪人和客户分布于全球',
        },
        {
          text1: '1000',
          text2: '亿',
          text3: '月交易量（美金）',
        },
      ],
      boxList: [
        {
          imgUrl: require('@/assets/newHome/icon01.png'),
          text1: '多国监管，合规放心',
          text2: '澳洲ASIC+瓦努阿图VFSC，安全无忧',
        },
        {
          imgUrl: require('@/assets/newHome/icon02.png'),
          text1: '产品丰富，选择多样',
          text2:
            '股指CFD、期货CFD、股票CFD、组合基金,CFD外汇、贵金属、能源、虚拟货币',
        },
        {
          imgUrl: require('@/assets/newHome/icon03.png'),
          text1: '入金方便，出金快捷',
          text2: '多种入金方式自由选择，出金最快2小时到账',
        },
        {
          imgUrl: require('@/assets/newHome/icon04.png'),
          text1: '科技创新，自主研发',
          text2: `Lifebyte科技公司自主研发跟单社区HUBX
                  “零”延迟跟单系统，打造最先进的交易方式`,
        },
        {
          imgUrl: require('@/assets/newHome/icon05.png'),
          text1: '更短延迟，最优报价',
          text2: '与全球多家顶级LP、交易所达成合作，提供最优报价和更低点差',
        },
        {
          imgUrl: require('@/assets/newHome/icon06.png'),
          text1: '金融服务，全面专业',
          text2: '提供流动性解决方案、白标服务、IB服务、风控服务等',
        },
      ],
      block3List: [
        {
          imgUrl: require('@/assets/newHome/icon10.png'),
          text1: 'IRESS',
          text2:
            '强大的DMA执行系统的行业领导者，投资者可在此平台上交易全球各大交易所的股票、国债',
        },
        {
          imgUrl: require('@/assets/newHome/icon11.png'),
          text1: 'TradeMax Insights',
          text2:
            '为机构、经纪交易商、基⾦及资产管理⼈提供实时的投资组合、风险管理与资产分配解决⽅案',
        },
        {
          imgUrl: require('@/assets/newHome/icon12.png'),
          text1: 'HUBx',
          text2:
            '集交易信号分享、跟随交易、社区交流互动为一体的真实、公开、透明的全方位平台',
        },
      ],
      b3Active: 0,
      footerList: [
        '用户条款',
        '风险提示',
        '隐私协议',
        '利益冲突政策',
        '差价合约的最佳执行政策',
        '投诉处理政策',
      ],
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  methods: {
    JumpWeb(url) {
      window.location.href = url
    },
    handleClick(index) {
      this.$refs.b3Swiper.swipeTo(index)
      this.b3Active = index
    },
    handleChange(index) {
      this.b3Active = index
    },
    async getBanner(lang) {
      let LanguageType = ''
      if (lang === 'zh') {
        LanguageType = 'Chinese'
      } else if (lang === 'en') {
        LanguageType = 'English'
      } else if (lang === 'ft') {
        LanguageType = 'Traditional'
      } else {
        LanguageType = 'Japanese'
      }
      this.rotationPics = await userApi.rotationPics({ LanguageType })
    },
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  },
  created() {
    this.getBanner(localStorage.getItem('language'))
    this.timer = setInterval(() => {
      this.percentage++
      if (this.percentage === 100) {
        this.$refs.swiper.next()
        this.percentage = 0
      }
    }, 50)
  },
}
</script>
<style lang="stylus" scoped>
@import '~@/styles/mixin.styl'
h1,h2,h3,h4,h5
  margin 0
  padding 0
  border 0
  font-size 100%
  font inherit
  vertical-align baseline
.container
  width 100%
  color #333
  font-size 14px
  padding 50px 0
  background #fff
  line-height 1.5
  .videos
    display block
    width 92%
    margin 20px auto 0
  .title
    position fixed
    left 0
    top 0
    width 100%
    text-align center
    height 50px
    display flex
    justify-content space-between
    align-items center
    z-index 9
    padding 0 15px
    background #fff
    img
      // width 160px
      height 100%
  .swipe-container
    .bg
      width 100%
      height 191px
    >>>.van-swipe__indicators
      bottom 15px
    >>>.van-swipe__indicator
      transition: all .3s;
      width 6px
      height 6px
      border-radius 0
    >>>.van-swipe__indicator--active
      width 50px
      background-image: linear-gradient(90deg, #06c878 29%, #0093df 100%), linear-gradient(#f5f7fa, #f5f7fa);
  .swipe-box
    position relative
    .btn
        background-image: linear-gradient(90deg, #05c576 0%, #09a867 100%), linear-gradient(#05c576, #05c576);
        height 35px
        border-radius 5px
        width 110px
        color #fff
        flex-center()
        position absolute
        top 60%
        left 50%
        transform translate(-50%,-50%)
  .van-progress
    margin-top -8px
    >>>.van-progress__portion
      transition: all .05s;
  .block-1
    padding 30px 15px
    .title-1
      font-size 16px
      color #0e0e0e
      position relative
      margin-bottom 10px
      line-height 1
      &:before
        content ''
        position absolute
        left -10px
        top -5px
        display inline-block
        width 13px
        height 25px
        background url('~@/assets/newHome/arrorright.png') 0 center no-repeat
        background-size 8px auto
    .title-2
      font-size 26px
      color #8e99a8
      margin-bottom 10px
    .content
      color #0e0e0e;
      margin-bottom 21px
      text-indent 2em
  .inabout-list
    width 100%
    height 87px
    display flex
    margin-bottom 10px
    .item
      flex 1
      padding 0 5px
      color: #00a74b;
      vertical-align: top;
      strong,i
        font-weight: normal;
        font-family: 'Montserrat';
        font-size: 30px;
        color: #06c878;
        background-image: linear-gradient(90deg, #06c878 29%, #0093df 100%), linear-gradient(#1f684a, #1f684a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      i
        font-size: 28px;
        background-image: linear-gradient(90deg, #02a5bc 29%, #0093de 100%), linear-gradient(#019ad1, #0095db);
      p
        color #8e99a8
  .more
    color: #0e0e0e;
    padding-left: 15px;
    .van-icon
      font-size 18px
      transform rotateZ(-90deg)
      position relative
      top 3px
      left 5px
  .iamge01
    width 100%
    height 385px
    margin-top 30px
  .block-2
    margin-top -6px
    background url('~@/assets/newHome/image02.jpg') left top  no-repeat
    padding: 20px 0 0;
    background-size: cover;
    .logo-text
      flex-center()
      height 60px
      font-size 20px
      color #fff
      img
        width 178px
        height 20px
    .box
      display flex
      justify-content flex-start
      flex-wrap wrap
      .item
        width 50%
        flex-column()
        justify-content flex-start
        padding 30px 10px 20px
        text-align center
        color #fff
        font-size 13px
        border-top 1px solid #ffffff
        &:nth-child(odd)
          border-right 1px solid #ffffff
        .top
          height 65px
          width 100px
          background url('~@/assets/newHome/icon07.png')
          background-size cover
          img
            width 52px
            height 52px
            position relative
            top 5px
        .middle
          font-size 16px
          margin 2px 0
  .block-3
    background #efefef
    padding 10px 0 10px
    .content
      margin 20px 10px
      height 353px
      border-radius 10px
      overflow hidden
      color #fff
      text-align center
      background url('~@/assets/newHome/image03.jpg')
      background-size cover
      box-shadow: 10px 10px 5px rgba(33, 66, 96, 0.15);
      h2
        font-size 20px
        margin 50px 0 20px
      .block3-swipe-container
        height 213px
        .van-swipe-item
          padding 0 10px
          img
            width 80px
            height 80px
            margin-bottom 10px
          h3
            font-size 16px
            margin-bottom 10px
          p
            margin-bottom 20px
          .more
            color #fff
    .indicator
        flex-center()
        span
          width 18px
          height 6px
          margin 0 3px
          background  #00a74b
          border-radius: 10px;
          display block
        .b3-active
          background-image: linear-gradient(90deg, #06c878 29%, #0093df 100%), linear-gradient(#f5f7fa, #f5f7fa);
  .footer
    padding 10px
    background #191919
  .block-4
    padding 30px 0
    color #fff
    border-bottom 1px solid #303030
    .footer-logo
      text-align center
      img
        width 223px
        margin-bottom 20px
    h4
      font-size 14px
    p
      font-size 16px
      text-indent 2em
  .footer-info
    padding 10px 20px
    color #6f7988
    text-align center
    .top
      margin-bottom 10px
      span
        display: inline-block;
        margin 0 3px
</style>
