<template>
  <div class="container">
    <div class="main-container">
      <img
        class="banner"
        src="@/assets/fourHome/banner.png"
        alt
        @click="$router.push({ name: 'Product' })"
      />
      <Notice color="#1989fa" background="#ecf9ff" :isFour="true" />
      <div class="text">
        火币威委托交易平台
        火币威委托管理交易平台成立于2017年4月17日，是综合性资产管理公司，截至2019年年底，公司总资产管理规模为9600多亿人民币，其中数字货币委托管理资产，公募基金资产规模为5760亿人民币，居于数字货币，证券，基金行业前三十
        [1]
        ，货币基金管理规模2524亿元，业内排名前十；社保及企业年金管理规模排名前三十；专户管理规模1442亿元，排名前五十。
        火币威业务范围遍布全球。拥有中国基金行业全部业务资格，包括了公募、社保、年金、专户、QDII、QFII、RQFII资格等。
        截至2019年12月31日，火币威旗下共管理100只公募基金产品以及多个全国社保基金资产组合、企业年金及特定客户资产管理组合、QFII/RQFII投资组合等；旗下公募基金2019年年底开户数超过2500万户，客户数量连年稳定增长；火币威成立以来累计分红880亿。
      </div>
      <div class="product-box">
        <div class="product-list">
          <router-link
            tag="div"
            class="item"
            :to="{ name: 'Product', params: { id: vitem.Id } }"
            v-for="(vitem, vindex) in productList"
            :key="vindex"
          >
            <div class="top">{{ getName(vitem.Id) }}</div>
            <div class="bottom">
              <span class="now-price"
                >{{ showPrice(vitem.Price)
                }}<i class="arrow" :class="{ Rise: vitem.Rise }"></i
              ></span>
              <van-divider class="border" :hairline="false" />
              <div class="mini-text">
                <span>{{ vitem.difference }}</span>
                <span style="color: #10d4b0ed">{{ vitem.percentage }}%</span>
              </div>
            </div>
          </router-link>
        </div>
        <router-link tag="div" to="Product" class="btn"
          >进入交易大厅</router-link
        >
      </div>
      <div class="text2">
        <div class="top">
          <span>易得其所、能动天下</span>
          <van-divider class="line" />
          <van-divider class="line1" />
        </div>
        <div class="bottom">
          火币威委托管理交易平台，秉承“易得其所、能动天下”的核心发展
          理念，以“公平、公正、稳定、安全”为宗旨，以“诚信、专业、创值、
          创新”的服务精神，服务至上、勇于革新，为全球跨 境投资者提供专
          业性、综合性服务。开启全球化投资的崭新时代！
        </div>
      </div>
      <div class="text2 second">
        <div class="top">
          <span>
            关于我们
            <i>ABOUT US</i>
          </span>
          <van-divider class="line" />
          <van-divider class="line1" />
        </div>
        <div class="bottom">
          火币威委托交易平台
          火币威委托管理交易平台成立于2017年4月17日，是综合性资产管理公司，截至2019年年底，公司总资产管理规模为9600多亿人民币，其中数字货币委托管理资产，公募基金资产规模为5760亿人民币，居于数字货币，证券，基金行业前三十
          [1]
          ，货币基金管理规模2524亿元，业内排名前十；社保及企业年金管理规模排名前三十；专户管理规模1442亿元，排名前五十。
          火币威业务范围遍布全球。拥有中国基金行业全部业务资格，包括了公募、社保、年金、专户、QDII、QFII、RQFII资格等。
          截至2019年12月31日，火币威旗下共管理100只公募基金产品以及多个全国社保基金资产组合、企业年金及特定客户资产管理组合、QFII/RQFII投资组合等；旗下公募基金2019年年底开户数超过2500万户，客户数量连年稳定增长；火币威成立以来累计分红880亿。
        </div>
        <router-link tag="div" to="Product" class="btn">了解更多</router-link>
      </div>
      <div class="text2 three">
        <div class="top"></div>
        <div class="bottom">
          经营稳健，管理规范：火币威高度重视健全内部管理体制和完善风险防范机制，成立伊始便在业内率先制定“内部控制大纲”，对平台治理结构、内部控制制度体系与制度管理、内部控制基本要点、控制环境、持续的控制检验作出规范，在此基础上制定了100余项规章制度，初步形成了一套具有平台特色、合乎数字货币，证券，基金行业规范运作要求的制度化管理体系，保证了平台的合规、高效运作，获得了世界权威中介评估机构的充分肯定。
          以人为本，人才优势明显：基金业是“人的事业”，火币威成立伊始，便努力打造一支高度专业化的金融综合管理团队。公司有员工18611名，平均年龄为31.2岁，其中具有硕士以上学历的占员工总数的62.7%，具有专业复合背景的占83.3%。公司高管人员人均金融从业时间11年以上，全部具有经济学或管理学教育背景。
          平台的投资研究队伍专业、稳健、勇于进取，现有投资和研究人员共370名，其中投资管理人员人均证券管理，基金管理，数字货币趋势分析从业时间8.2年，具有专业复合背景的占35.7%，研究员全部具有硕士以上学历，其中具有理工科背景的占68%，具有专业复合背景的占43.7%。
          业绩稳定，回报丰厚：完善的制度、严格的管理、规范的运作使火币威平台始终保持各方面业务持续增长的良性发展态势，投资业绩综合排名始终在世界金融业界中名列前茅。截止2019年12月30日，为投资者创造了良好的回报。
          研究主导，投研良性互动：火币威一贯秉承价值投资理念，强调研究在投资中的主导作用，不断追求研究的深度和前瞻性。平台已自主培养和建立起一支高素质的研究员队伍，形成了研究主导的投资文化，注重深度挖掘、实地调研、持续跟踪。投资与研究相互促进、良性互动，为平台取得优异投资业绩打下了坚实基础。
          服务客户，细致深入：专业坦诚是火币威的服务理念。平台不断致力于改进客户服务质量、加强多方沟通协调、完善和创新服务方式。平台已与超过500家机构签订了合作协议，并推出了开放式金融综合类的网上交易平台，努力为广大客户提供更多的交易便利和优惠。火币威将继续坚持以诚信和业绩立足，不断开拓创新，积极进取，努力为投资者创造最优回报。
        </div>
      </div>
      <div class="introduce">
        <h2>产品说明</h2>
        <h3>PRODUCT MANUAL</h3>
        <div class="content">
          我们最大的资产是我们的员工，火币威委托管理交易平台只聘用那些富有经验
          的专业人员，他们已经在金融服务业展示了他们一流的客户服务技巧和领带能力。
          提供专业的客户服务。我们的销售和营运代表将以专业
          的中文对客户的任何询问进行解答。
        </div>
        <div class="text-box" v-for="item in list" :key="item.title">
          <div class="title">{{ item.title }}</div>
          <div
            class="content1"
            v-for="(sItem, index) in item.texts"
            :key="index"
          >
            <div class="item">{{ sItem }}</div>
          </div>
        </div>
        <router-link tag="div" to="Product" class="btn">了解更多</router-link>
      </div>
      <div class="introduce market">
        <h2>沪深股市</h2>
        <div
          class="text-box"
          v-for="(mItem, mIndex) in mList"
          :key="`a${mIndex}`"
        >
          <div
            class="content1"
            v-for="(mMitem, mMindex) in mItem"
            :key="`b${mMindex}`"
          >
            <div class="item">
              {{ mMitem.title }}
            </div>
            <div class="text1">{{ mMitem.text1 }}</div>
            <div class="text1">{{ mMitem.text2 }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="tips">
      风险提示：所有投资都存在风险。任何一支证券或金融产品的历史数据都不能保证其未来的表现及回报。虽然投资多样化可以帮助分散风险，但是并不能在市场低迷时确保您获益或防止您损失。投资证券或金融产品总会存在潜在的亏损可能。投资者在进行投资前需考虑自身的投资目标及风险承受能力。
    </div>
    <TabBar bg="#f1f1f1" active="" />
  </div>
</template>

<script>
import TabBar from '@/components/tabBar'
import productApi from '@/api/product'
import Notice from './notice'
import { mapGetters } from 'vuex'
export default {
  components: {
    TabBar,
    Notice,
  },
  data() {
    return {
      productList: [],
      ProductNames: [],
      productBasic: [],
      list: [
        {
          title: '开户须知',
          texts: [
            '开设真实账户（注册会员）之前一定要认真阅读和查看本公司的《交易规则》和《风险揭示与告知》。',
            '客户具备的开户条件：年满十八周岁、具有完全民事行为能力的公民。客户须以真实的、合法的身份开户并保证资金来源的合法性。',
            '客户自备用于相互转账的银行卡（开通网上银行），客户应妥善保管好银行密码及交易密码，并定期更换密码，不得向任何人泄露密码。',
            '自行注册，并遵循《一人一个账号规则》。',
            '正式账户充值成功后即可开始交易。',
          ],
        },
        {
          title: '操盘须知',
          texts: [
            '您投资的 本金 最低 1 元;',
            '申请资金后，随时买进卖出;',
            '账户匹配后，当天即可交易;',
            '当资金接近亏损强平预警线时，需尽快补足保证金；如未及时补仓，可能会被风控系统强平;',
            '当操盘资金低于平仓线下时，我们有权将您的账户实行止损操作;',
            '单个账户持仓不得超过账户总资金的 90.00% ;',
          ],
        },
      ],
      mList: [
        [
          {
            title: '标的名称：',
            text1: '上海、深圳股市融资融券板块',
            text2: '',
          },
          {
            title: '市场交易时间：',
            text1: '第一节：09:35-12:30 第二节：13:05-15:00',
            text2: '（北京时间）(实际开放交易时间以交易规则为准）',
          },
          {
            title: '最小交易单位：',
            text1: '0.01手 ，每1手=100股',
            text2: '',
          },
          {
            title: '最大交易单位：',
            text1: '100000.00手 ，每1手=100股',
            text2: '',
          },
          {
            title: '交易方式：',
            text1:
              '可买多、卖空，买升、买趺、当日买入，当日平仓，賺取当日波段振幅价差！',
            text2: '',
          },
        ],
        [
          {
            title: '交易额度：',
            text1: '采用融资、融券方式，买涨买跌方式买进卖出',
            text2: '',
          },
          {
            title: '最小购买金额：',
            text1: '1 元',
            text2: '',
          },
          {
            title: '手续费：',
            text1: '买入及卖出时收取，依交易总价 * 0.003 收取',
            text2: '',
          },
          {
            title: '印花税：',
            text1: '买入时收取，依交易总价 * 0.0003 收取',
            text2: '',
          },
          {
            title: '留仓费：',
            text1:
              '如该交易未於收盘前平仓，则该交易於收盘后记为留仓单，收取该股收盘价 * 留仓股数 * 0 留仓费，（节假日亦收取）',
            text2: '',
          },
        ],
        [
          {
            title: '最大留仓天数：',
            text1: '15 天',
            text2: '',
          },
          {
            title: '出金规则：',
            text1: '当前有持仓无法出金，最小出金金额为 100 元',
            text2: '',
          },
          {
            title: '出金时间：',
            text1: '出金时间为 9 :00 和 15 : 00 之间',
            text2: '',
          },
          {
            title: '时差：',
            text1: '夏令：0小时；冬令：0小时',
            text2: '',
          },
          {
            title: '数据来源：',
            text1: '中国上海证券交易所及深训证券交易所',
            text2: '',
          },
        ],
      ],
    }
  },
  computed: {
    ...mapGetters(['connection']),
  },
  destroyed() {
    if (this.connection.connectionState === 'Connected') {
      this.connection.invoke('SendMsg', '')
    }
  },
  mounted() {
    this.Basic()
    this.signalRStart()
  },
  methods: {
    async Basic() {
      this.productBasic = await productApi.Basic()
    },
    showPrice(Price) {
      if (Price >= 100) {
        return Price.toFixed(2)
      } else if (Price < 100 && Price > 0.1) {
        return Price.toFixed(4)
      } else {
        return Price
      }
    },
    getName(Id) {
      let name = ''
      this.ProductNames.some((item) => {
        if (item.Id === Id) {
          name = item.Name
          return true
        }
      })
      return name
    },
    signalRStart() {
      let that = this
      let connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'home')
        }
      }, 100)
      connection.on('HomeSecondPrices', function (res) {
        res.forEach((item) => {
          that.productBasic.some((sItem) => {
            if (item.Id === sItem.Id) {
              item.difference = item.Price - sItem.BasicPrice
              item.difference = item.difference.toFixed(2)
              item.percentage = (item.difference / item.Price) * 100
              item.percentage = item.percentage.toFixed(2)
              return true
            }
          })
        })
        that.productList = res
      })
      connection.on('ProductNames', function (res) {
        that.ProductNames = res
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/mixin.styl'
h1,h2,h3,h4,h5
  margin 0
  padding 0
  border 0
  font-size 100%
  font inherit
  vertical-align baseline
div
  box-sizing border-box
.container
  background #ffffff
  font-size 14px
  padding-bottom 40px
  .main-container
    padding 10px
  .text
    text-indent 2em
    background #F7F7F7
    color #666
    font-size 14px
    line-height 1.6
    padding 10px 8px
    margin 15px 0
    border-radius 5px
  .product-box
    border-radius 10px
    box-shadow 0 3px 15px #eee
    padding-bottom 15px
    .product-list
      white-space nowrap
      overflow-x auto
      overflow-y hidden
      height 95px
      .item
        width 31vw
        display inline-block
        text-align center
        padding 0 10px
        .top
          font-size 17px
          font-weight bold
          color #177BDC
          margin 12px 0
        .bottom
          .border
            margin 0
          .now-price
            display inline-block
            color #333
            font-size 14px
            padding-bottom 3px
            position relative
            .arrow
              position absolute
              right -10px
              top 2px
              display inline-block
              width 6px
              height 10px
              background url('~@/assets/fourHome/download.png') 0 center no-repeat
              background-size 100% 100%
            .Rise
              background-image url('~@/assets/fourHome/top.png')
          .mini-text
            margin-top 7px
            padding 0 3px
            font-size 12px
            color #999
            flex-between()
  .btn
    color #fff
    background #0C73DA
    font-size 16px
    width 220px
    height 40px
    text-align center
    line-height 40px
    margin 0px auto
    border-radius 30px
  .text2
    border-radius 10px
    margin 15px auto
    overflow hidden
    box-shadow 0 3px 15px #eee
    .top
      height 70px
      width 100%
      background url('~@/assets/fourHome/bg1.png') 0 center no-repeat
      background-size 100% 100%
      font-size 16px
      color #fff
      font-weight bold
      span
        display inline-block
        margin 25px 0 0 20px
      i
        color rgb(255, 212, 71)
        font-style normal
        font-weight bold
      .line
        width 160px
        margin 8px 15px 0
      .line1
        margin 3px 15px
        width 185px
    .bottom
      text-indent 2em
      font-size 14px
      line-height 1.5
      color #666
      padding 10px 8px
      // margin-bottom 15px
  .second
    padding-bottom 15px
    .top
      background-image url('~@/assets/fourHome/bg2.png')
  .three
    .top
      height 120px
      background-image url('~@/assets/fourHome/bg3.png')
  .introduce
    border-radius 10px
    margin 15px auto
    box-shadow 0 3px 15px #eee
    text-align center
    padding 10px 8px 20px
    color #666666
    h2
      font-size 16px
      color #0C73DA
      margin 5px 0 10px
    h3
      margin 10px 0 15px
      font-size 15px
    .content
      text-align left
      text-indent 2em
      font-size 14px
      line-height 1.5
    .text-box
      background #f7f7f7
      padding-bottom 10px
    .title
      color #fff
      font-size 16px
      line-height 2
      background #FF9D53
      margin 10px 0
    .content1
      text-align left
      line-height 1.5
      padding 0 15px 5px 25px
      font-size 14px

      .item
        position relative
        &::before
          content ''
          position absolute
          display inline-block
          width 5px
          height 5px
          border-radius 50%
          top 8px
          left -10px
          transform translate(-50%, -50%)
          background #FF7700
    .btn
      margin-top 20px
  .market
    padding-bottom 5px
    .text-box
      margin-bottom 10px
      padding 10px 0
      .item
        font-size 15px
        margin 5px 0
      .text1
        padding-left 5px
        font-size 12px
        color #999
  .tips
    color #fff
    padding 15px 10px
    background #0C73DA
    font-size 12px
    line-height 1.6
</style>
