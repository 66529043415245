<template>
  <div class="currency-info">
    <div class="currency-title">
      <div class="col" v-for="pItem in tableBox" :key="pItem">
        {{ pItem }}
      </div>
    </div>

    <router-link
      :to="{ name: 'Product', params: { id: vitem.Id } }"
      tag="div"
      class="currency-item"
      v-for="(vitem, vindex) in productList"
      :key="vindex"
      :class="{ up: vitem.Rise }"
    >
      <div class="col product-name">
        <van-image round width="25px" height="25px" :src="getLogo(vitem.Id)" />
        <span>{{ getName(vitem.Id) }}</span>
      </div>
      <div class="col block">{{ showPrice(vitem.Price) }}</div>
      <div class="col">{{ showPrice(vitem.Low) }}</div>
      <div class="col">{{ showPrice(vitem.Top) }}</div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Token } from '@/utils/mm'
import productApi from '@/api/product'
import userApi from '@/api/user'
export default {
  data() {
    return {
      logined: Token.get() ? true : false,
      tableBox: ['货币名称', '现价', '最低', '最高'],
      productList: [],
      ProductNames: [],
    }
  },
  computed: {
    ...mapGetters(['connection']),
  },
  methods: {
    async getList() {
      this.productList = await productApi.list()
      this.productList.forEach((item) => {
        item.name = this.showProductName(item.Id)
      })
      const ProductId = this.$route.params.id
      this.ProductId = ProductId ? ProductId : this.productList[0].Id
      this.Basic()
    },
    async getUserInfo() {
      let res = await userApi.userInfo()
      this.NickName = res.NickName
    },     
    async Basic() {
      let res = await productApi.Basic()
      this.productBasic = res
      // 信息处理
      this.productBasic.forEach((item) => {
        item.difference = item.Price - item.BasicPrice
        item.difference = Number(item.difference).toFixed(2)
        item.percentage = (item.difference / item.Price) * 100
        item.percentage = Number(item.percentage).toFixed(2)
      })
      // productlist赋值
      this.productList.forEach((item1) => {
        this.productBasic.forEach((item2) => {
        if(item1.Id == item2.Id)
        {
            item1.BasicPrice = item2.BasicPrice            
            item1.Price = item2.Price
            item1.Rise = item2.Rise            
            item1.difference = item2.difference
            item1.percentage = item2.percentage
            if(item2.Low == null || item2.Low == '')
            {
              item1.Low = item2.Price
            }else
            {
              item1.Low = item2.Low
            }
            if(item2.Top == null || item2.Top == '')
            {
              item1.Top = item2.Price
            }else{
              item1.Top = item2.Top
            }
          }
        })
      })
      this.productNowInfo = this.productList
      this.$store.commit('SET_PRODUCTLIST', this.productList)
      // 到此处productlist初次获取值已经完成
    },
    showProductName(ProductId) {
      let name = ''
      this.productList.forEach((item) => {
        if (item.Id === ProductId) {
          if (this.lang === 'zh') {
            name = item.Name
          } else if (this.lang === 'en') {
            name = item.EnglishName
          } else {
            name = item.TraditionalName
          }
          return
        }
      })

      return name
    },
    showPrice(Price) {
      Price = Price - 0
      if (Price >= 100) {
        return Price.toFixed(2)
      } else if (Price < 100 && Price > 0.1) {
        return Price.toFixed(4)
      } else {
        return Price
      }
    },
    signalRStart() {
      let that = this
      let connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'home')//这行才是订阅
        }
      }, 100)
      connection.on('HomeSecondPrices', function (res) {
        that.getSecondData(res)
      })
      connection.on('ProductNames', function(res) {
        that.ProductNames = res
      })
    },
    getLogo(Id) {
      let logo = ''
      this.ProductNames.some((item) => {
        if (item.Id === Id) {
          logo = item.Logo
          return true
        }
      })
      return logo
    },
    getName(Id) {
      let name = ''
      this.ProductNames.some((item) => {
        if (item.Id === Id) {
          name = item.Name
          return true
        }
      })
      return name
    },
    async getSecondData(data) {
      // console.log(data)
      // data是推送来的数据
      let productNowInfo = data
      // console.log(data)
      this.productList.forEach((item) => {
        // 所有列表
        productNowInfo.forEach((sitem) => {
          // 推送列表
          if (item.Id === sitem.Id) {
            item.Price = sitem.Price
            item.Rise = sitem.Rise
            item.Low = sitem.Low
            item.Sort = sitem.Sort
            item.Top = sitem.Top
          }
        })
      })
      function sortId(a, b) {
        return a.Sort - b.Sort
      }
      this.productList.sort(sortId)
    },
  },
  destroyed() {
    if (this.connection.connectionState === 'Connected') {
      this.connection.invoke('SendMsg', '')
    }
  },
  created() {
    this.signalRStart()
  },
  mounted() {
    this.getList()
    if (Token.get()) {
      this.getUserInfo()
    }
    this.signalRStart()
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/mixin.styl'
.currency-info
  // margin-top 20px
  .currency-title, .currency-item
    background #425870
    color #fff
    flex-center()
    height 35px
    border-bottom 1px solid #dcd6d6
    text-align center
    .col
      width 25vw
      ellipsis()
    .product-name
      font-size 12px
      padding-left 5px
      color #000000
      text-align left
      line-height 45px
      .van-image
        position relative
        top 8px
        margin-right 3px
  .currency-item
    background none
    height 45px
    color #6bb86d
    .block
      padding 8px 5px
      background #6bb86d
      border-radius 5px
      color #fff
  .up
    .block
      background #f6293b
</style>
