<template>
  <div class="container">
    <div class="title">
      <div class="logo_r">
        <img src="@/assets/xpgyHome/logo.png" class="logo_img" alt="" />
        <div class="text">{{ $t('xpgyHome').title }}</div>
      </div>
      <div>
        <router-link tag="span" class="login-btn" to="Login" v-if="!this.NickName">
          {{ $t('index').PleaseLogin }}
        </router-link>
        <span @click="showLanguage = true">{{ $t('index').lang }}</span>
      </div>
    </div>
    <swiper style="margin-top: 20px;" class="service-advantage" ref="carouselSwiper" :options="swiperOptions">
      <swiper-slide v-for="(slide, i) in pics" :key="i">
        <img :src="slide" class="simg">
      </swiper-slide>
    </swiper>
    <div class="article-title">
      {{ $t('xpgyHome').articleTitle }}
    </div>
    <div class="article-time">2021-12-02</div>
    <div class="article1">
      {{ $t('xpgyHome').articleContent1 }}
    </div>
    <img src="@/assets/xpgyHome/c1.jpg" class="article_img" alt="" />
    <div class="article1">
      {{ $t('xpgyHome').articleContent2 }}
    </div>
    <img src="@/assets/xpgyHome/c2.jpg" class="article_img" alt="" />
    <div class="article1">
      {{ $t('xpgyHome').articleContent3 }}
    </div>
    <div class="article1">
      {{ $t('xpgyHome').articleContent4 }}
    </div>
    <img src="@/assets/xpgyHome/c3.jpg" class="article_img" alt="" />
    <div class="article1">
      {{ $t('xpgyHome').articleContent5 }}
    </div>
    <div class="line"></div>
    <div class="logo_r">
      <img src="@/assets/xpgyHome/logo.png" class="logo_img" alt="" />
      <div class="text">{{ $t('xpgyHome').title }}</div>
    </div>
    <div class="footer">
      <div class="f_text">© {{ $t('xpgyHome').footer1 }}</div>
      <div class="f_text">{{ $t('xpgyHome').footer2 }}</div>
      <div class="f_text_img">
        <img src="@/assets/xpgyHome/jh.png" class="f_img" alt="" />
        <div class="txt">{{ $t('xpgyHome').footer3 }}</div>
      </div>
      <div class="f_text">{{ $t('xpgyHome').footer4 }}</div>
    </div>
    <TabBar bg="#f1f1f1" active="" />
    <Language :showLanguage="showLanguage" @closePopup="showLanguage = false" />
  </div>
</template>

<script>
import Language from '@/components/language'
import TabBar from '@/components/tabBar'
import { Token } from '@/utils/mm'
import userApi from '@/api/user'
export default {
  components: {
    Language,
    TabBar,
  },
  data() {
    return {
      showLanguage: false,
      NickName: '',
      logined: Token.get() ? true : false,
      pics: [
        require('@/assets/xpgyHome/ban1.jpg'),
        require('@/assets/xpgyHome/ban2.jpg'),
        require('@/assets/xpgyHome/ban3.jpg'),
        require('@/assets/xpgyHome/ban4.jpg'),
      ],
      swiperOptions: {
        slidesPerView: 1.2, //设置slider容器能够同时显示的slides数量,可以是小数,设置为2时,如图所示，设置为3则会出现三张完整的active slide，如API的例子,即设置为偶数时会自动使两边的缩进，类似遮盖一半的效果
        spaceBetween: -15,
        centeredSlides: true,//设定为true时，active slide会居中，而不是默认状态下的居左
        loop: true,
        slideToClickedSlide: true,//true:点击slide会过渡到这个slide,默认false
        autoplay: {
          delay: 5000
        },
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // }
      }

    }
  },
  computed: {
  },
  methods: {
    async getUserInfo() {
      let res = await userApi.userInfo()
      this.NickName = res.NickName
    },
  },
  mounted() {
    if (Token.get()) {
      this.getUserInfo()
    }
  },
}
</script>
<style lang="stylus" scoped>
.service-advantage{
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;
  margin-top: 10px;
}

.simg{
  height: 110px;
  width: 500px;
  border-radius: 5px;
}
.swiper-slide {
	width:100%;
  height: 110px;
	text-align: center;
	font-size: 18px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 300ms;
	transform: scale(0.8);
	border-radius: 12px;
}
.swiper-slide-active
.swiper-slide-duplicate-active {
	transform: scale(1);
}

@import '~@/styles/mixin.styl';

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.container {
  width: 100%;
  color: #fff;
  font-size: 14px;
  padding: 50px 0;
  background: #fff;

  .title {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    z-index: 9;
    background-color: #ffffff;

    img {
      // width 130px
      height: 50px;
    }

    span {
      padding: 0 5px;
      margin-right: 10px;
      color: #000;
      height: 35px;
      line-height: 35px;
      display: inline-block;
      border: 1px solid #a8401d;
      border-radius: 5px;
      box-sizing: border-box;
    }

    .login-btn {
      background: #a8401d;
      font-weight: bold;
      color: #fff;
      font-size: 15px;
      border: none;
      padding: 0 5px;
    }
  }

  .logo_r {
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 10px;

    .text {
      font-size: 15px;
      color: #000;
      font-weight: bold;
      max-width: 150px;
    }

    .logo_img {
      height: 50px;
      width: 50px;
    }
  }

  .line {
    height: 5px;
    background: #f7f7f7;
  }

  .ban_img {
    padding: 20px 0;
    width: 100%;
    height: 130px;
  }

  .article-title {
    font-size: 15px;
    color: #000;
    padding: 0 8px;
    text-align: center;
    font-weight: bold;
    line-height: 1.5;
  }

  .article-time {
    font-size: 12px;
    color: #adadad;
    padding: 10px 0;
    text-align: center;
  }

  .article {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      width: 43vw;
      margin: 5px;
      border: 1px solid #0957ac;
      overflow: hidden;
      border-radius: 5px;

      .top {
        background: #0957ac;
        height: 26px;
        font-weight: bold;
        font-size: 14px;
        flex-center();
      }

      .bottom {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 3vw;

        img {
          width: 78px;
          height: 78px;
          border-radius: 50%;
          border: 1px solid #0957ac;
          margin: 10px;
        }

        p {
          margin: 3px 0;
          font-size: 13px;
          color: #000000;
          text-align: center;
        }
      }
    }
  }

  .article1 {
    font-size: 18px;
    color: #000;
    padding: 0 16px;
    line-height: 1.7;
    margin-bottom: 20px;
  }

  .article_img {
    margin-bottom: 20px;
    width: 100%;
  }

  .footer {
    background: #273037;
    padding: 10px 0 0 0;
    align-items: center;

    .f_text {
      padding: 8px 0;
      font-size: 13px;
      color: #fff;
      text-align: center;
    }

    .f_text_img {
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 8px 0;

      .txt {
        text-align: center;
        font-size: 13px;
        color: #fff;
      }

      .f_img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
}
</style>
