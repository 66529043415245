<template>
  <div class="container">
    <div class="title">
      <img :src="basicConfig.Logo" alt="" />
      <div>
        <router-link
          tag="span"
          class="login-btn"
          to="Login"
          v-if="!this.NickName"
        >
          {{ $t('index').PleaseLogin }}
        </router-link>
        <span @click="showLanguage = true">{{ $t('index').lang }}</span>
      </div>
    </div>
    <div class="swipe-box">
      <van-swipe class="swipe-container" :autoplay="3000">
        <van-swipe-item
          v-for="(item, index) in rotationPics"
          :key="index"
          @click="JumpWeb(item.JumpUrl)"
        >
          <img class="bg" :src="item.ImgUrl" alt="" />
          <!-- <p>{{ $t('index').topSwiper[index].text1 }}</p>
        <p>{{ $t('index').topSwiper[index].text2 }}</p>
        <p v-if="$t('index').topSwiper[index].text3">
          {{ $t('index').topSwiper[index].text3 }}
        </p> -->
          <!-- <img
          class="logo"
          :src="basicConfig.BannerLogo"
          alt=""
          v-show="index === 0"
        /> -->
        </van-swipe-item>
      </van-swipe>

      <!-- <div class="btn" to="Register" v-if="NickName">
          {{ $t('index').Welcome }}：{{ NickName }}
        </div> -->
      <router-link
        tag="div"
        class="btn"
        to="Register"
        v-if="!logined && basicConfig.IsShowBtn"
      >
        {{ $t('index').openAccount }}
      </router-link>
    </div>

    <Notice />
    <div class="currency-info">
      <div class="currency-title">
        <div class="col" v-for="pItem in $t('index').tableBox" :key="pItem">
          {{ pItem }}
        </div>
      </div>
      <router-link
        class="currency-item"
        v-for="(vitem, vindex) in productList"
        :key="vindex"
        :to="{ name: 'Product', params: { id: vitem.Id } }"
        :class="{ up: vitem.Rise }"
      >
        <div class="col product-name">
          <van-image
            round
            width="25px"
            height="25px"
            :src="getLogo(vitem.Id)"
          />
          <!-- <span>{{ getName(vitem.Id) }}</span> -->
          <span>{{vitem.name }}</span>

        </div>
        <div class="col block">
          {{ showPrice(vitem.Price) }}
        </div>
        <div class="col">{{ showPrice(vitem.Low) }}</div>
        <div class="col">{{ showPrice(vitem.Top) }}</div>
      </router-link>
    </div>
    <div class="article-title">{{ $t('index').introduce1.title }}</div>
    <div class="article">
      <div
        class="item"
        v-for="aItem in this.$t('index').introduce1.data"
        :key="aItem.title"
      >
        <div class="top">{{ aItem.title }}</div>
        <div class="bottom">
          <img :src="aItem.imgUrl" alt="" />
          <p>{{ aItem.text1 }}</p>
          <p>{{ aItem.text2 }}</p>
          <p>{{ aItem.text3 }}</p>
        </div>
      </div>
    </div>
    <div class="article-title">
      {{ regexp($t('index').introduce2.title) }}
    </div>
    <div class="article1 indent">
      {{ regexp($t('index').introduce2.content) }}
    </div>
    <video
      v-if="!basicConfig.IsHideVideo"
      controls="controls"
      :src="basicConfig.Video"
      :poster="basicConfig.VideoCover"
      class="videos"
    ></video>
    <div class="article-title">
      {{ $t('index').introduce3.title }}
    </div>
    <div class="article1 indent">
      {{ regexp($t('index').introduce3.content) }}
    </div>
    <van-swipe class="bottom-swipe-container" :autoplay="3000">
      <van-swipe-item
        v-for="(bItem, bIndex) in $t('index').bottomSwiper"
        :key="bIndex"
      >
        <div class="top">{{ regexp(bItem.text1) }}</div>
        <p class="indent">{{ regexp(bItem.text2) }}</p>
        <div class="more">
          <router-link to="Product" tag="span"
            >{{ $t('index').learnMove }} >></router-link
          >
        </div>
      </van-swipe-item>
    </van-swipe>
    <TabBar bg="#f1f1f1" active="" />
    <Language :showLanguage="showLanguage" @closePopup="showLanguage = false" />
  </div>
</template>

<script>
import Language from '@/components/language'
import TabBar from '@/components/tabBar'
import Notice from './notice'
import { Token } from '@/utils/mm'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import productApi from '@/api/product'
export default {
  components: {
    Language,
    TabBar,
    Notice,
  },
  data() {
    return {
      showLanguage: false,
      NickName: '',
      logined: Token.get() ? true : false,
      productList: [],
      rotationPics: [],
      productNowInfo: [],
      ProductNames: [],
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection']),
    companyName() {
      let name = ''
      let basicConfig = this.basicConfig
      if (this.lang === 'zh') {
        name = basicConfig.CompanyName
      } else if (this.lang === 'en') {
        name = basicConfig.CompanyNameEnglish
      } else if (this.lang === 'ft') {
        name = basicConfig.CompanyNameTraditional
      } else {
        name = basicConfig.CompanyNameJapanese
      }
      return name
    },
  },
  methods: {
    showProductName(ProductId) {
      let name = ''
      this.productList.forEach((item) => {
        if (item.Id === ProductId) {
          if (this.lang === 'zh') {
            name = item.Name
          } else if (this.lang === 'en') {
            name = item.EnglishName
          } else {
            name = item.TraditionalName
          }
          return
        }
      })

      return name
    },
    async getList() {
      this.productList = await productApi.list()
      this.productList.forEach((item) => {
        item.name = this.showProductName(item.Id)
      })
      const ProductId = this.$route.params.id
      this.ProductId = ProductId ? ProductId : this.productList[0].Id
      this.Basic()
    },
    async Basic() {
      let res = await productApi.Basic()
      this.productBasic = res
      // 信息处理
      this.productBasic.forEach((item) => {
        item.difference = item.Price - item.BasicPrice
        item.difference = Number(item.difference).toFixed(2)
        item.percentage = (item.difference / item.Price) * 100
        item.percentage = Number(item.percentage).toFixed(2)
      })
      // productlist赋值
      this.productList.forEach((item1) => {
        this.productBasic.forEach((item2) => {
          if(item1.Id == item2.Id)
          {
            item1.BasicPrice = item2.BasicPrice            
            item1.Price = item2.Price
            item1.Rise = item2.Rise            
            item1.difference = item2.difference
            item1.percentage = item2.percentage
            if(item2.Low == null || item2.Low == '')
            {
              item1.Low = item2.Price
            }else
            {
              item1.Low = item2.Low
            }
            if(item2.Top == null || item2.Top == '')
            {
              item1.Top = item2.Price
            }else{
              item1.Top = item2.Top
            }
          }
        })
      })
      this.productNowInfo = this.productList
      this.$store.commit('SET_PRODUCTLIST', this.productList)
      // 到此处productlist初次获取值已经完成
    },
    showPrice(Price) {
      Price = Price - 0
      if (Price >= 100) {
        return Price.toFixed(2)
      } else if (Price < 100 && Price > 0.1) {
        return Price.toFixed(4)
      } else {
        return Price
      }
    },
    JumpWeb(url) {
      window.location.href = url
    },
    regexp: function (str) {
      let str1 = str.replace(
        new RegExp('CompanyName ', 'g'),
        this.basicConfig.CompanyName
      )
      str1 = str1.replace(
        new RegExp('CompanyNameEnglish ', 'g'),
        this.basicConfig.CompanyNameEnglish
      )
      str1 = str1.replace(
        new RegExp('CompanyNameTraditional ', 'g'),
        this.basicConfig.CompanyNameTraditional
      )
      str1 = str1.replace(
        new RegExp('CompanyNameJapanese ', 'g'),
        this.basicConfig.CompanyNameJapanese
      )
      str1 = str1.replace(
        new RegExp('CompanyFullName ', 'g'),
        this.basicConfig.CompanyFullName
      )
      str1 = str1.replace(
        new RegExp('CompanyFullNameEnglish ', 'g'),
        this.basicConfig.CompanyFullNameEnglish
      )
      str1 = str1.replace(
        new RegExp('CompanyFullNameTraditional ', 'g'),
        this.basicConfig.CompanyFullNameTraditional
      )
      str1 = str1.replace(
        new RegExp('CompanyFullNameJapanese ', 'g'),
        this.basicConfig.CompanyFullNameJapanese
      )

      return str1
    },
    async getUserInfo() {
      let res = await userApi.userInfo()
      this.NickName = res.NickName
    },
    getLogo(Id) {
      let logo = ''
      this.ProductNames.some((item) => {
        if (item.Id === Id) {
          logo = item.Logo
          return true
        }
      })
      return logo
    },
    async getBanner(lang) {
      let LanguageType = ''
      if (lang === 'zh') {
        LanguageType = 'Chinese'
      } else if (lang === 'en') {
        LanguageType = 'English'
      } else if (lang === 'ft') {
        LanguageType = 'Traditional'
      } else {
        LanguageType = 'Japanese'
      }
      this.rotationPics = await userApi.rotationPics({ LanguageType })
    },
    getName(Id) {
      let name = ''
      this.ProductNames.some((item) => {
        if (item.Id === Id) {
          if (this.lang === 'zh') {
            name = item.Name
          } else if (this.lang === 'en') {
            name = item.EnglishName
          } else {
            name = item.TraditionalName
          }
          return true
        }
      })
      return name
    },
    signalRStart() {
      let that = this
      let connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'home')//这行才是订阅
        }
      }, 100)
      connection.on('HomeSecondPrices', function (res) {
        that.getSecondData(res)
      })
      connection.on('ProductNames', function(res) {
        that.ProductNames = res
      })
    },
    async getSecondData(data) {
      // console.log(data)
      // data是推送来的数据
      let productNowInfo = data
      // console.log(data)
      this.productList.forEach((item) => {
        // 所有列表
        productNowInfo.forEach((sitem) => {
          // 推送列表
          if (item.Id === sitem.Id) {
            item.Price = sitem.Price
            item.Rise = sitem.Rise
            item.Low = sitem.Low
            item.Sort = sitem.Sort
            item.Top = sitem.Top
          }
        })
      })
      // productNowInfo.forEach((item, index) => {
      //   this.productList.some((pItem, pIndex) => {
      //     if (pItem.Id === item.ProductId) {
      //       productNowInfo[index].Sort = pIndex
      //       return true
      //     }
      //   })
      // })
      function sortId(a, b) {
        return a.Sort - b.Sort
      }
      this.productList.sort(sortId)
      // this.productNowInfo = this.productList
      // if (!this.dataMoeActiveIndex) {
      //   // 在这里可以获取ldata初始数据
      //   data.forEach((item) => {
      //     if (item.ProductId === this.ProductId) {
      //       this.newPrice = item.Price
      //       // this.Ldata.push(item.Price)
      //       // this.split.push('')
      //       if (this.Ldata.length > 100) {
      //         this.Ldata.shift()
      //         this.split.shift()
      //       }
      //     }
      //   })
      // }
    },
  },

  destroyed() {
    if (this.connection.connectionState === 'Connected') {
      this.connection.invoke('SendMsg', '')
    }
  },
  mounted() {
    this.getList()
    if (Token.get()) {
      this.getUserInfo()
    }
    this.getBanner(localStorage.getItem('language'))
    EventBus.$on('changeLanguage', (lang) => {
      this.getBanner(lang)
    })
    this.signalRStart()
  },
}
</script>
<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
h1,h2,h3,h4,h5
  margin 0
  padding 0
  border 0
  font-size 100%
  font inherit
  vertical-align baseline
.container
  width: 100%;
  color #fff
  font-size 14px
  padding 50px 0
  background: #fff;
  .title
    position fixed
    left 0
    top 0
    width 100%
    text-align center
    height 50px
    display flex
    justify-content space-between
    align-items center
    font-size 14px
    z-index 9
    background-color #0380d0
    img
      // width 130px
      height 50px
    span
      padding 0 10px
      margin-right 10px
      height 35px
      line-height 35px
      display inline-block
      border 1px solid #ffffff
      border-radius 5px
      box-sizing border-box
    .login-btn
      background #80b644
      font-weight bold
      font-size 15px
      border none
      padding 0 15px
  .swipe-container
    overflow hidden
    height 400px
    .van-swipe-item
      position relative
      .bg
        position absolute
        z-index -1
        left 0
        right 0
        width 100%
        height 100%
      .logo
        display block
        width 75px
        height 75px
        margin 25px auto
      p
        font-size 25px
        font-weight bold
        text-align center
        color #fff
        margin 15px 0
        padding 4px 0
        &:nth-of-type(1)
          padding-top 35px
        &:nth-of-type(3)
          font-size 19px
          margin-top 30px
          font-weight 400
  .swipe-box
    position relative
    .btn
      background #f39700
      height 40px
      border-radius 5px
      font-size 17px
      width 190px
      flex-center()
      position absolute
      top 80%
      left 50%
      transform translate(-50%,-50%)
  .van-notice-bar
    height 35px
    line-height 35px !important
    >>> .van-notice-bar__wrap
      line-height 30px
    .notice
      margin-right 60px
  .currency-info
    .currency-title,.currency-item
      background: #d1cece;
      color: #292929;
      flex-center()
      height 35px
      border-bottom: 1px solid #dcd6d6;
      text-align center
      .col
        width 25vw
        ellipsis()
      .product-name
        font-size 12px
        padding-left 5px
        color: #000000;
        text-align left
        line-height 45px
        .van-image
          position relative
          top 8px
          margin-right 3px
    .currency-item
        background none
        height 45px
        color #6bb86d
        .block
          padding 8px 5px
          background #6bb86d
          border-radius 5px
          color #fff
    .up
      .block
        background #f6293b
  .article-title
    font-size 20px
    color #222222
    margin 35px 0
    text-align center
    font-weight bold
  .article
    padding 0 15px
    display flex
    justify-content space-between
    flex-wrap wrap
    .item
      width 43vw
      margin 5px
      border 1px solid #0957ac
      overflow hidden
      border-radius 5px
      .top
        background #0957ac
        height 26px
        font-weight bold
        font-size 14px
        flex-center()
      .bottom
        display flex
        align-items center
        flex-direction column
        padding 3vw
        img
          width 78px
          height  78px
          border-radius 50%
          border 1px solid #0957ac
          margin 10px
        p
          margin 3px 0
          font-size 13px
          color #000000
          text-align center
  .article1
    font-size 13px
    color #5c5c5c
    padding 0 15px
    line-height 1.7
    margin-bottom 20px
  .videos
    display block
    width 80%
    margin 0 auto
  .bottom-swipe-container
    background #0957ac
    min-height 310px
    padding-bottom 20px
    text-align center
    .top
      font-size 20px
      font-weight bold
      margin 40px 0
    p
      padding 0 15px 25px
      line-height 1.8
      font-size 13px
    .more
      margin-top 25px
      span
        font-size 17.5px
        display inline-block
        padding-bottom 4px
        border-bottom 1px solid #ffffff
  .indent
    text-indent 2em
</style>
