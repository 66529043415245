<template>
  <div>
    <first v-if="basicConfig.Sign === 'first'" />
    <second v-if="basicConfig.Sign === 'TradeMax'" />
    <three v-if="basicConfig.Sign === 'TMGM'" />
    <four v-if="basicConfig.Sign === 'HotCoin'" />
    <atfx v-if="basicConfig.Sign === 'ATFX'"></atfx>
    <xpgy v-if="basicConfig.Sign === 'XPGY'"></xpgy>
    <div style="font-size:1px;color:#ffffff">{{ randomTxt }}</div>
  </div>
</template>

<script>
import first from './components/first'
import second from './components/second'
import three from './components/three'
import four from './components/four'
import atfx from './components/atfx'
import xpgy from './components/xpgy'
import { mapGetters } from 'vuex'
import { IsreadSysNotice } from '@/utils/validate'
export default {
  components: {
    first,
    second,
    three,
    four,
    atfx,
    xpgy,
  },
  data() {
    return {
      randomTxt: "",
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  mounted() {
    this.randomTxt = Math.random().toString(36).substring(2, 15) + Math.floor(Date.now()) + Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    this.$nextTick(() => {
      setTimeout(() => {
        let PopupNotices = this.basicConfig.PopupNotices
        if (PopupNotices && PopupNotices.length && IsreadSysNotice()) {
          let item = PopupNotices[0]
          this.$dialog.alert({
            title: item.Title,
            message: item.Content,
          })
        }
      }, 500)
    })
  },
}
</script>

<style></style>
